<template>
  <v-container fluid id="avisos" class="primary py-12">
    <v-row>
      <v-col
        cols="12"
        sm="12"
        class="d-flex justify-center align-center flex-column py-12"
      >
        <h2 class="secondary--text text-center">Avisos de privacidad</h2>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="pb-8">
      <v-col cols="12" md="10">
        <v-row>
          <v-col cols="12" lg="4">
            <v-btn
              outlined
              color="secondary"
              rounded
              large
              block
              href="/AVISO DE PRIVACIDAD CORTO - PROVEEDORES.docx"
            >
              Aviso de privacidad Corto
            </v-btn>
          </v-col>
          <v-col cols="12" lg="4">
            <v-btn
              outlined
              color="secondary"
              rounded
              large
              block
              href="/AVISO DE PRIVACIDAD SIMPLIFICADO - PROVEEDORES.docx"
            >
              Aviso de privacidad Simplificado
            </v-btn>
          </v-col>
          <v-col cols="12" lg="4">
            <v-btn
              outlined
              color="secondary"
              rounded
              large
              block
              href="/AVISO DE PRIVACIDAD INTEGRAL - PROVEEDORES.docx"
            >
              Aviso de privacidad Integral
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
.icon-size {
  height: 40px;
}
</style>
<script>
import SvgIcon from "@jamescoyle/vue-icon";
import {
  mdiSeal,
  mdiRadar,
  mdiThumbUpOutline,
  mdiHandHeartOutline,
} from "@mdi/js";

export default {
  name: "Steps",
  components: {
    SvgIcon,
  },
  data: () => ({
    sealPath: mdiSeal,
    radarPath: mdiRadar,
    thumbPath: mdiThumbUpOutline,
    handPath: mdiHandHeartOutline,
  }),
  computed: {},
  methods: {
    goToPolitica() {
      this.$router.push("/politica-de-privacidad");
    },
  },
};
</script>
